import { Component, Input, OnInit } from '@angular/core';
import { map, Observable } from 'rxjs';

import { FeatureFlagNames } from '@app/core/feature-flag/shared/feature-flag.type';
import { LaunchDarklyService } from '@app/core/launch-darkly/launchdarkly.service';

import { DocumentSummary } from '../../../../../graphql/onelife.type';
import { GetDocumentSummaryQueryService } from '../../shared/document-summary.onelife.generated';
import { UnavailableSummary } from '../../shared/unavailable-summary';

@Component({
  selector: 'omg-document-summary',
  templateUrl: './document-summary.component.html',
  styleUrls: ['./document-summary.component.scss'],
})
export class DocumentSummaryComponent implements OnInit {
  @Input() documentId: number;
  documentSummarizationVisible: boolean;
  documentSummaryFeedbackVisible: boolean;
  summary$: Observable<DocumentSummary>;

  constructor(
    private launchDarklyService: LaunchDarklyService,
    private documentSummaryService: GetDocumentSummaryQueryService,
  ) {}

  ngOnInit(): void {
    this.documentSummarizationVisible = this.launchDarklyService.variation(
      FeatureFlagNames.chartDocumentSummarizationVisible,
      false,
    );
    this.documentSummaryFeedbackVisible = this.launchDarklyService.variation(
      FeatureFlagNames.chartDocumentSummarizationFeedback,
      false,
    );
    this.initSummary();
  }

  initSummary(): void {
    this.summary$ = this.documentSummaryService
      .fetch({ documentId: this.documentId.toString() })
      .pipe(map(result => result.data.document.summary || UnavailableSummary));
  }
}
