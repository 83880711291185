<div *ngIf="documentSummarizationVisible"
     class="document-summary">

  <omg-collapse [expanded]=true>
    <omg-collapse-toggle>
      <div om-layout
           om-layout-align="start center">
        <i class="title-wand om-icon icon-magic"></i>
        <span class="document-summary-title">
          AI Document Summary
        </span>
        <omg-collapsed disableAnimation="true">
          <i class="fa fa-chevron-down"></i>
        </omg-collapsed>
        <omg-expanded disableAnimation="true">
          <i class="fa fa-chevron-up"></i>
        </omg-expanded>
      </div>
    </omg-collapse-toggle>

    <ng-container *ngrxLet="summary$ as summary; suspenseTpl: loading">

      <omg-expanded>
        <div *ngIf="summary.data.detailedSummary; else unavailable">
          <div class="document-summary-text">
            {{summary.data.detailedSummary}}
          </div>
          <omg-document-summary-timestamp [summary]="summary" />
        </div>
      </omg-expanded>

      <omg-collapsed>
        <div *ngIf="summary.data.detailedSummary; else unavailable"
             class="document-summary-text-collapsed">
          {{summary.data.detailedSummary}}
        </div>
      </omg-collapsed>
    </ng-container>

    <ng-template #loading>
      <omg-document-summary-loading />
    </ng-template>

    <ng-template #unavailable>
      <omg-document-summary-unavailable />
    </ng-template>

  </omg-collapse>

  <div *ngIf="documentSummaryFeedbackVisible">
    <omg-document-summary-feedback />
  </div>

</div>
